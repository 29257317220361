<template>
  <div class="vownermanagement">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="姓名">
            <el-input v-model="form.name" placeholder="请输入姓名" clearable></el-input>
          </el-form-item>
          <!-- 搜索框 -->
          <el-form-item label="电话号码">
            <el-input v-model="form.phone" placeholder="请输入电话号码" clearable></el-input>
          </el-form-item>

          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-plus" @click="edit()">添加</el-button>
          </el-form-item>
          <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="reset()">重置</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table
          ref="multipleTable"
          :data="tabData"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column prop="name" label="姓名" align="center"></el-table-column>
          <el-table-column prop="hospName" label="医院名称" align="center"></el-table-column>
          <el-table-column prop="account" label="账号" align="center"></el-table-column>
          <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
          <el-table-column prop="password" label="密码" align="center"></el-table-column>
          <el-table-column label="操作" align="center" min-width="200px">
            <template slot-scope="scope">
              <el-button @click="edit(scope.row)" size="mini" type="primary" icon="el-icon-edit" >修改</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[10,15, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <el-dialog
        title="修改信息"
        :visible.sync="dialogVisible"
        width="50%">
        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="demo-ruleForm">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                </el-col>
                 <el-col :span="12">
                    <el-form-item label="医院名称" prop="hospitalId">
                         <el-select v-model="ruleForm.hospitalId" placeholder="请选择">
                            <el-option
                                v-for="item in historyOptions"
                                :key="item.uuid"
                                :label="item.hospName"
                                :value="item.uuid">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <!-- <el-col :span="12">
                    <el-form-item label="账     号" prop="account">
                        <el-input v-model="ruleForm.account"></el-input>
                    </el-form-item>
                </el-col> -->
                 <el-col :span="12">
                    <el-form-item label="手 机 号" prop="phone">
                        <el-input v-model="ruleForm.phone"></el-input>
                    </el-form-item>
                </el-col>
                 <el-col :span="12">
                    <el-form-item label="密     码" prop="password">
                        <el-input v-model="ruleForm.password"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <el-row>
                <el-col :span="12">
                    <el-form-item label="密     码" prop="password">
                        <el-input v-model="ruleForm.password"></el-input>
                    </el-form-item>
                </el-col>
            </el-row> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitForm('ruleFormRef')">确 定</el-button>
        </span>
    </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
import { checkPhone } from '@/utils/rules.js'
export default {
  name: 'userManagement',
  components: {
  },
  data () {
    return {
      historyOptions: [], // 医院列表
      tabData: [], // 表格数据源
      form: { ownerName: '' }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      dialogVisible: false,
      ruleForm: {
        name: '', phone: '', hospitalId: '', account: '', password: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: ['blur', 'change'] }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: ['blur', 'change'] }
        ],
        hospitalId: [
          { required: true, message: '请输入医院名称', trigger: ['blur', 'change'] }
        ],
        account: [
          { required: true, message: '请输入账号', trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  created () {
    this.getAllCar()
    this.getHistoryOptions()
  },
  methods: {
    /**
     * 重置
     */
    reset () {
      this.paging.PageNo = 1
      for (const key in this.form) {
        this.form[key] = ''
      }
      this.getAllCar()
    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    /**
     * 编辑弹窗
     */
    edit (row) {
      this.dialogVisible = true
      if (!row) {
        if (this.$refs.ruleFormRef) {
          this.ruleForm = {
            name: '', phone: '', hospitalId: '', account: '', password: ''
          }
          this.$refs.ruleFormRef.resetFields()
        }
      } else {
        for (const key in row) {
          this.ruleForm[key] = row[key]
        }
      }
    },
    /**
     * 医院列表
     */
    async getHistoryOptions () {
      const { data: res } = await this.$http.post('/pmService/hospital/list', {})
      this.historyOptions = res.data
    },
    /**
     * 新增
     */
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.ruleForm.account = this.ruleForm.phone
          console.log(this.ruleForm.account, 'this.ruleForm.account ')
          const { data: res } = await this.$http.post('/pmService/hospitalAccount/submit', {
            ...this.ruleForm
          })
          if (res.statusCode == 200) {
            this.dialogVisible = false
            this.getAllCar()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 获取所有车主列表
    async getAllCar () {
      const { data: res } = await this.$http.post('/pmService/hospitalAccount/page', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        ...this.form
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取车主列表失败')
      this.tabData = res.data.records
      this.total = +res.data.total
    },
    // 搜索
    search () {
      this.pageNo = 1
      this.getAllCar()
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    }

  }
}
</script>
<style lang="less" scoped>
.vownermanagement {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
.el-select{
    width: 100%;
}
</style>
